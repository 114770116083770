import { Game, ImageAsset, Maybe, PbsKidsTheme } from '@/types/pbskids-graph';

import GameCard from '../GameCard';
import GlobalStateContext from '@/components/global/GlobalState';
import Image from 'next/image';
import { CSSProperties, useContext, useEffect, useRef } from 'react';
import ThemedModule from '@/components/global/ThemedModule';

import styles from './GamesCharacterFeatureCollage.module.scss';

export enum CharacterFeatureCollageLayouts {
  default = 'default',
  firstEntryFeatured = 'firstEntryFeatured',
}

// Constraints for MVP release. The only two scenarios to support for now:
// - First featured -- with only one game
// - First featured -- with total three games
// We will support up to 4 games eventually, and also the "default" layout.

const getFilteredGames = (useMvpRules: boolean, games: Game[]) => {
  // The CMS shouldn't ever return more than 4, but just in case, let's chop those extras off, since they'll break the layout
  const hardLimit = 4;
  const gameLimit = useMvpRules ? games.length >= 3 ? 3 : 1 : hardLimit;

  return games.slice(0, gameLimit);
};

const getLayoutClasses = (useMvpRules: boolean, layout: string, filteredGames: Game[]) => {
  const layoutToApply = useMvpRules ? CharacterFeatureCollageLayouts.firstEntryFeatured : layout;

  const layoutModeClass =
    layoutToApply === CharacterFeatureCollageLayouts.firstEntryFeatured ?
      styles.layoutModeFirstEntryFeatured :
      styles.layoutModeDefault;

  const itemCountClass = filteredGames.length === 1 ? styles.countOneGame :
    filteredGames.length === 2 ? styles.countTwoGame :
      filteredGames.length === 3 ? styles.countThreeGame :
        filteredGames.length === 4 ? styles.countFourGame : '';

  return `${layoutModeClass} ${itemCountClass}`;
};

export default function GamesCharacterFeatureCollage({
  games,
  layout = CharacterFeatureCollageLayouts.default,
  listId,
  characterImage,
  theme,
  useMvpRules = true,
}: {
  games: Game[];
  layout?: string;
  listId?: string
  characterImage?: Maybe<Maybe<ImageAsset>[]>;
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>
  useMvpRules?: boolean;
}) {
  const { showThemeDevUI, showDevUI } = useContext(GlobalStateContext);
  const wrapperRef = useRef<HTMLDivElement|null>(null);
  const filteredGames = getFilteredGames(useMvpRules, games);

  useEffect(() => {
    wrapperRef?.current?.style?.setProperty(
      '--pbsk-component-games-character-feature-collage-game-count', filteredGames.length.toString(),
    );
  });

  // Require a theme, per Ivy's request
  if (
    (!Array.isArray(theme) && !theme) ||
    (Array.isArray(theme) && !(((theme as Array<PbsKidsTheme>)?.length || 0) > 0))
  ) {
    return <></>;
  }

  // Require at least one game
  if (filteredGames.length < 1) {
    return <></>;
  }

  // Require a valid character image
  if (!characterImage?.[0]?.url || !characterImage?.[0]?.width || !characterImage?.[0]?.height) {
    return null;
  }

  const imageSizes = '(min-width: 1024px) 225px, (min-width: 768px) 300px, 360px';
  const featuredGameImageSizes = '(min-width: 1024px) 487px, (min-width: 768px) 630px, 710px';

  const labelStyle = {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 2,
    borderRadius: '4px',
    backgroundColor: 'var(--pbsk-color-dark-gray)',
    color: '#fff',
    padding: '8px 16px',
    margin: 0,
    fontSize: '8px',
    maxHeight: '100%',
    overflow: 'auto',
  } as CSSProperties;

  const layoutClassNames = getLayoutClasses(useMvpRules, layout, filteredGames);

  const DebugTag = () => <pre style={labelStyle}>
    {JSON.stringify(
      {
        useMvpRules,
        layoutRequested: layout,
        inputGamesCount: games.length,
        filteredGameCount: filteredGames.length,
      },
      null, 2,
    )}
  </pre>;

  return (
    <>
      <ThemedModule
        theme={theme}
        backgroundMode='image'
        contentClassName={styles.themedContentWrapper}
      >
        {(showThemeDevUI || showDevUI) && <DebugTag />}

        <div ref={wrapperRef} className={`${styles.wrapper} ${layoutClassNames}`}>
          <ul
            data-ga-view-list-module={listId}
            className={styles.theGrid}
          >
            {filteredGames.map((game: Game, index: number) => (
              <li
                key={ index }
                className={styles.gamesListItem}
              >
                <GameCard
                  enableLookInside={ false }
                  key={ index }
                  gameItem={ game }
                  sizes={
                    layout === CharacterFeatureCollageLayouts.firstEntryFeatured && index === 0 ?
                      featuredGameImageSizes :
                      imageSizes
                  }
                ></GameCard>
              </li>
            ))}
          </ul>
          <div className={styles.characterImage}>
            <Image
              src={characterImage?.[0].url}
              width={characterImage?.[0].width}
              height={characterImage?.[0].height}
              alt=''
            />
          </div>
        </div>
      </ThemedModule>
    </>
  );
}
